module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'buttons-panel/in-column' && templateVars.config ?
				templateVars.config :
				{
	"modifier": "in-column",
	"ifEmptyPanel": "remove",
	"animation": {
		"list": {
			"show": {
				"effect": "transition.slideUpIn",
				"duration": 200
			},
			"hide": {
				"effect": "transition.slideDownOut",
				"duration": 200
			}
		},
		"sublist": {
			"show": {
				"effect": "slideDown",
				"duration": 200
			},
			"hide": {
				"effect": "slideUp",
				"duration": 200
			}
		}
	},
	"events": {
		"list": {
			"click": {
				"show": "click touchstart",
				"hide": "click touchstart"
			},
			"hover": {
				"show": "touchstart mouseenter",
				"hide": "mouseover touchstart"
			}
		},
		"sublist": {
			"click": {
				"show": "click touchstart",
				"hide": "click touchstart"
			},
			"hover": {
				"show": "touchstart mouseenter",
				"hide": "mouseover touchstart"
			}
		}
	},
	"hoverablePanel": false,
	"hoverableSublist": false,
	"tools": {
		"panelType": "compact",
		"title": {
			"ru": "Инструменты",
			"en": "Tools"
		},
		"sublistSlideType": "bottom",
		"InMyReport": false,
		"buttonWithText": false,
		"offlineHideButtons": [
			"feedback",
			"share",
			"social"
		],
		"buttonsPanelHideButtons": [
			"myReport",
			"addToReport",
			"delFromReport",
			"printVersion",
			"pdf",
			"compare",
			"glossary",
			"disclaimer",
			"archive",
			"history",
			"download"
		],
		"hideButtonsOnSystem": [
			"addToReport",
			"delFromReport",
			"printVersion",
			"pdf",
			"share"
		],
		"hideButtonsOnMainPage": [
			"addToReport",
			"delFromReport",
			"printVersion",
			"pdf"
		],
		"hideCurrentButtonOnPage": true,
		"showTour": true,
		"sorting": {
			"ru": [
				"myReport",
				"addToReport",
				"delFromReport",
				"pdf",
				"feedback",
				"printVersion",
				"download",
				"history",
				"siteMap",
				"analysis",
				"compare",
				"glossary",
				"disclaimer",
				"archive"
			],
			"en": [
				"myReport",
				"addToReport",
				"delFromReport",
				"pdf",
				"feedback",
				"printVersion",
				"download",
				"history",
				"siteMap",
				"analysis",
				"compare",
				"glossary",
				"disclaimer",
				"archive"
			]
		},
		"links": [
			{
				"id": "myReport",
				"modifier": "myreport",
				"targetBlank": false,
				"url": "${rootUrl}/my-report",
				"text": {
					"ru": "Мой отчет",
					"en": "My report"
				}
			},
			{
				"id": "addToReport",
				"modifier": "add js-add-page",
				"targetBlank": false,
				"url": "javascript:;",
				"text": {
					"ru": "Добавить в «Мой отчет»",
					"en": "Add to My Report"
				}
			},
			{
				"id": "pdf",
				"modifier": "pdf js-get-pdf",
				"targetBlank": true,
				"url": "${pdf}",
				"text": {
					"ru": "Скачать PDF",
					"en": "PDF version"
				}
			},
			{
				"id": "delFromReport",
				"modifier": "del js-del-page",
				"targetBlank": false,
				"url": "javascript:;",
				"text": {
					"ru": "Удалить из «Моего отчета»",
					"en": "Delete from My Report"
				}
			},
			{
				"id": "printVersion",
				"modifier": "print js-print",
				"targetBlank": true,
				"url": "javascript:;",
				"text": {
					"ru": "Печать страницы",
					"en": "Print version"
				},
				"event": "window.print();"
			},
			{
				"id": "history",
				"modifier": "history",
				"targetBlank": false,
				"url": "${rootUrl}/history",
				"text": {
					"ru": "История просмотра",
					"en": "Browsing history"
				}
			},
			{
				"id": "feedback",
				"modifier": "feedback js-feedback-form-button",
				"targetBlank": false,
				"url": "javascript:;",
				"text": {
					"ru": "Обратная связь",
					"en": "Feedback"
				}
			},
			{
				"id": "download",
				"modifier": "download",
				"targetBlank": false,
				"url": "${rootUrl}/downloads",
				"text": {
					"ru": "Центр загрузки",
					"en": "Download center"
				}
			},
			{
				"id": "siteMap",
				"modifier": "site-map",
				"targetBlank": false,
				"url": "${rootUrl}/sitemap",
				"text": {
					"ru": "Карта сайта",
					"en": "Sitemap"
				}
			}
		]
	},
	"download-pdf": {
		"panelType": "compact",
		"title": {
			"ru": "Скачать PDF",
			"en": "Download"
		},
		"text": {
			"ru": "Скачать PDF",
			"en": "Download"
		},
		"slideType": "vertical",
		"buttonWithText": true,
		"offlineHideButtons": [],
		"buttonsPanelHideButtons": [],
		"sorting": {
			"ru": [
				"pdfPages",
				"pdfSpreads"
			],
			"en": [
				"pdfPages",
				"pdfSpreads"
			]
		},
		"links": [
			{
				"id": "pdfPages",
				"modifier": "pdf-pages",
				"targetBlank": true,
				"url": "/download/full-reports/${site}_${lang}_annual-report_pages_vtb_2019.pdf",
				"text": {
					"ru": "Постранично",
					"en": "Page by page"
				},
				"trackerParams": {
					"en": {
						"type": "click",
						"url": "en_buttons-panel-download-pdf_download-pdf-pages"
					},
					"ru": {
						"type": "click",
						"url": "ru_buttons-panel-download-pdf_download-pdf-pages"
					}
				}
			},
			{
				"id": "pdfSpreads",
				"modifier": "pdf-spreads",
				"targetBlank": true,
				"url": "/download/full-reports/${site}_${lang}_annual-report_spreads_vtb_2019.pdf",
				"text": {
					"ru": "Разворотами",
					"en": "Spreads"
				},
				"trackerParams": {
					"en": {
						"type": "click",
						"url": "en_buttons-panel-download-pdf_download-pdf-spreads"
					},
					"ru": {
						"type": "click",
						"url": "ru_buttons-panel-download-pdf_download-pdf-spreads"
					}
				}
			}
		]
	}
};