import path from 'path';
import config from './config.yaml';

/**
 * Класс реализует логику варианта base для компонента my-report
 */
class CMyReport_base {
	constructor() {
		this.config = config;
	}

	/**
	 * Инициализация
	 */
	async init() {
		this.$myReportWrapper = $('.js-my-report-wrapper');

		if (this.$myReportWrapper.length) {
			this.$mockup = this.$myReportWrapper.find('.js-mockup');
			this.$myReport = this.$myReportWrapper.find('.js-my-report');
			this.$myReportList = this.$myReportWrapper.find('.js-my-report-list');
			this.$selectAllItemsTrigger = this.$myReportWrapper.find('.js-my-report-all-trigger');

			this.$btnPrint = this.$myReportWrapper.find('.js-my-report-print');
			this.$btnSave = this.$myReportWrapper.find('.js-my-report-save');
			this.$btnDelete = this.$myReportWrapper.find('.js-my-report-delete');
			this.$btnShare = this.$myReportWrapper.find('.js-my-report-share');

			await this.initStorage();
			await this.renderList()
				.then(() => {
					this.initBtnGroupTriggers();
					this.initItemsTriggers();

					this.checkButtonsGroupAccess();
					return this.initSortable();
				})
				.then(() => {
					AR.components.cPreloader_base.disablePreloaderInItem(this.$myReportWrapper);
				});
		}
	}

	/**
	 * Инициализация хранилища
	 */
	initStorage() {
		return this._getLocalForage()
			.then(localforage => {
				return this.localforage = localforage.createInstance({
					name: `my-report_${templateVars.site}_${templateVars.lang}`,
					driver: this.getDriver(this.config.driver, localforage) || [localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE]
				});
			});
	}

	/**
	 * Получение библиотеки сортировки
	 */
	async _getSortable() {
		const lib = await import(/* webpackChunkName: "sortable" */ 'sortablejs/modular/sortable.core.esm.js');
		return AR.getEsm(lib);
	}

	/**
	 * Получение библиотеки хранилища
	 */
	async _getLocalForage() {
		const lib = await import(/* webpackChunkName: "localforage" */ 'localforage');
		return AR.getEsm(lib);
	}

	/**
	 * Сгенерить список моего отчета
	 */
	renderList() {
		return this.getList()
			.then(result => {
				if (result) {
					result.forEach((item, i) => {
						const markup = this.getItemMarkupForItem(item, i);
						this.$myReportList.append(markup);
					});

					return this.hideMockup()
						.then(() => this.showReport());
				}
			});
	}

	/**
	 * Проверка на инициализацию хранилища
	 */
	checkStorageExist() {
		if (!this.localforage) {
			return this.initStorage();
		}
		return Promise.resolve();
	}

	/**
	 * Получить драйвера для хранилища
	 * @param {object, string} driver драйвер
	 * @param {object} localforage библиотека хранилища
	 */
	getDriver(driver, localforage) {
		if (Array.isArray(driver)) {
			return driver.map(item => localforage[item]);
		} else if (typeof driver == 'string') {
			return localforage[driver];
		}
		return false;
	}

	/**
	 * Получить список страниц моего отчёта
	 */
	getList() {
		return this.getLength()
			.then(dbLength => {
				const itemsArray = [];
				return this.localforage.iterate(function (value, key, iterationNumber) {
					itemsArray.push(value);
					if (iterationNumber === dbLength) {
						return itemsArray;
					}
				});
			});
	}

	/**
	 * Получить количество страниц в моем отчете
	 */
	async getLength() {
		await this.checkStorageExist();
		return this.localforage.length().then(value => {
			return value;
		});
	}

	/**
	 * Получить элемент списка
	 * @param {object} item параметры элемента
	 * @param {string} id идентификатор
	 */
	getItemMarkupForItem(item, id) {
		const title = item.title,
			tagTitle = item.tagTitle,
			link = item.link,
			relativeLink = item.relativeLink;

		return `<div class="b-my-report__row js-my-report-row js-my-report-sortable" data-id="${link}">
			<div class="b-my-report__row-action">
				<div class="b-checkbox">
					<input type="checkbox" id="chbx${id}" class="b-checkbox__input js-my-report-trigger">
					<label class="b-checkbox__label" for="chbx${id}"></label>
				</div>
			</div>
			<div class="b-my-report__row-content">
				<a href="${link}" data-relative-path="${relativeLink}" class="b-my-report__link js-link" target="_blank" title="${tagTitle}">${title}</a>
			</div>
		</div>`;
	}

	/**
	 * Сохранение и получение сортировки
	 */
	initSortable() {
		return this._getSortable()
			.then(Sortable => {
				return Sortable.create(this.$myReportList[0], {
					group: 'MyReportSort',
					filter: '.js-my-report-nosortable',
					draggable: '.js-my-report-sortable',
					store: {
						get: function (sortable) {
							const order = localStorage.getItem(sortable.options.group.name);
							return order ? order.split('|') : [];
						},

						set: function (sortable) {
							const order = sortable.toArray();
							localStorage.setItem(sortable.options.group.name, order.join('|'));
						}
					}
				});
			});
	}

	/**
	 * Добавить в мой отчёт текущую страницу, либо страницу с указанными параметрами.
	 * Параметры задаются в виде объекта с полями {url, title, site, lang}
	 * @param {object} pageOptions параметры страницы
	 */
	addPage(pageOptions = {}) {
		const url = pageOptions.url || templateVars.siteUrl.path;
		const relativeUrl = pageOptions.pathTail || templateVars.siteUrl.pathTail;
		const pageTitle = templateVars.page.shortTitle ? templateVars.page.shortTitle : templateVars.page.title;

		return this.localforage.setItem(url, {
			type: 'my-report',
			site: pageOptions.site || templateVars.site,
			lang: pageOptions.lang || templateVars.lang,
			link: url,
			relativeLink: relativeUrl,
			title: pageOptions.title || pageTitle,
			tagTitle: pageOptions.title || templateVars.page.title
		});
	}

	/**
	 * Получить ссылки (data-attr) чекнутых элементов для генерации пдф
	 */
	getActiveLinks() {
		const $triggers = this.getCheckedItems();
		const links = [];

		if ($triggers.length) {
			$.each($triggers, (i, trigger) => {
				const link = $(trigger)
					.closest('.js-my-report-row')
					.find('.js-link')
					.attr('data-relative-path');

				if (link) {
					links.push(link);
				}
			});
		}

		return links;
	}

	/**
	 * Получить ссылки (href) чекнутых элементов для удаления из storage
	 */
	getActiveURLs() {
		const $triggers = this.getCheckedItems();
		const links = [];

		if ($triggers.length) {
			$.each($triggers, (i, trigger) => {
				const link = $(trigger)
					.closest('.js-my-report-row')
					.find('.js-link')
					.attr('href');

				if (link) {
					links.push(link);
				}
			});
		}

		return links;
	}

	/**
	 * Получить чекнутые элементы
	 */
	getCheckedItems() {
		const $triggers = this.getTriggersItems();

		return $triggers.filter((i, item) => {
			let $item = $(item);
			//берем чекнутые чекбоксы, но исключаем тот, котоыре выделяет все элементв
			return (!$item.hasClass('js-my-report-all-trigger') && $item.prop('checked'));
		});
	}

	/**
	 * Получить чекбокс для выделеия всех элементов
	 */
	getTriggersItems() {
		return this.$myReport.find('.js-my-report-trigger:not(.js-my-report-all-trigger)');
	}

	/**
	 * Очистить мой отчёт
	 */
	async cleanMyReport() {
		await this.checkStorageExist();
		return this.localforage.clear();
	}

	/**
	 * Проверить текущую страницу на существование в моем отчете
	 * @param {string} pagePath
	 */
	async checkPageOnExist(pagePath) {
		await this.checkStorageExist();
		return this.localforage.getItem(pagePath).then(value => {
			return value;
		});
	}

	/**
	 * Удалить элемент из моего отчет
	 * @param {string} key ключ страницы
	 */
	removeItem(key) {
		return this.localforage.removeItem(key);
	}

	/**
	 * Провесить слушателей события на кнопки
	 */
	initBtnGroupTriggers() {
		if (this.$btnPrint.length) {
			this.$btnPrint.on('click touch', $.proxy(this, 'onPrintClickEvent'));
		}

		if (this.$btnSave.length) {
			this.$btnSave.on('click touch', $.proxy(this, 'onSaveClickEvent'));
		}

		if (this.$btnDelete.length) {
			this.$btnDelete.on('click touch', $.proxy(this, 'onDeleteClickEvent'));
		}
	}

	/**
	 * Провесить слушателей события на смену состояния чекбоксов
	 */
	initItemsTriggers() {
		this.$myReport.on('change', $('.js-my-report-trigger'), $.proxy(this, 'onTriggerChangeEvent'));
	}

	/**
	 * Проверить и установить состояние кнопок
	 */
	checkButtonsGroupAccess() {
		const $checkedItems = this.getCheckedItems();
		const $btns = [this.$btnPrint, this.$btnSave, this.$btnDelete, this.$btnShare];

		$btns.forEach(($btn) => {
			if ($btn.length) {
				if ($checkedItems.length) {
					$btn.prop('disabled', false);
					$btn.removeClass('is-disabled');
				} else {
					$btn.prop('disabled', true);
					$btn.addClass('is-disabled');
				}
			}
		});
	}

	/**
	 * callback на смену состояния чекбокса
	 * @param {object} e событие смены состояния
	 */
	onTriggerChangeEvent(e) {
		const $trigger = $(e.target);

		if ($trigger[0] === this.$selectAllItemsTrigger[0]) {
			this.$selectAllItemsTrigger
				.closest('.b-checkbox')
				.removeClass('is-partly-selected');

			const $triggers = this.$myReport.find('.js-my-report-trigger');

			if ($trigger.prop('checked') == true) {
				$triggers.prop('checked', true);
			} else {
				$triggers.prop('checked', false);
			}
		} else {
			this.checkCheckboxesState();
		}

		this.checkButtonsGroupAccess();
	}

	/**
	 * Проверка состояний чекбоксов
	 */
	checkCheckboxesState() {
		const $triggers = this.getTriggersItems();
		const $checkedTriggers = this.getCheckedItems();

		if ($triggers.length == $checkedTriggers.length) {
			this.$selectAllItemsTrigger
				.closest('.b-checkbox')
				.removeClass('is-partly-selected');

			this.$selectAllItemsTrigger.prop('checked', true);
		} else {
			if ($checkedTriggers.length == 0) {
				this.$selectAllItemsTrigger
					.closest('.b-checkbox')
					.removeClass('is-partly-selected');
			} else {
				this.$selectAllItemsTrigger
					.closest('.b-checkbox')
					.addClass('is-partly-selected');
			}
			this.$selectAllItemsTrigger.prop('checked', false);
		}
	}

	/**
	 * callback на клик по кнопке печати
	 * @param {object} e событие клика
	 */
	onPrintClickEvent(e) {
		e.preventDefault();

		this.printReport();
	}

	/**
	 * callback на клик по кнопке сохранить
	 * @param {object} e событие клика
	 */
	onSaveClickEvent(e) {
		e.preventDefault();

		this.saveReport();
	}

	/**
	 * callback на клик по кнопке удалить
	 * @param {object} e событие клика
	 */
	onDeleteClickEvent(e) {
		e.preventDefault();

		this.deletePagesFromReport();
	}

	/**
	 * Собрать печатную версию и открыть окно печати
	 */
	printReport() {
		const query = this.getActiveLinks()
			.map(file => `myReportLinks[]=${encodeURIComponent(file)}`)
			.concat([
				`filename=files`,
				`pageTitle=${encodeURIComponent(templateVars.page.title)}`,
				`pathRoot=${encodeURIComponent(templateVars.langObj.pathRoot)}`,
				`siteType=${encodeURIComponent(templateVars.site)}`,
				`langType=${encodeURIComponent(templateVars.lang)}`
			])
			.join('&');

		let myWindow = window.open(path.join((templateVars.siteLangRootUrlRel) ? templateVars.siteLangRootUrlRel : '/', `printMyReport?${query}`), '_blank');
		myWindow.focus();
		myWindow.print();
	}

	/**
	 * Собрать пдф и скачать
	 */
	saveReport() {
		const query = this.getActiveLinks()
			.map(file => `myReportLinks[]=${encodeURIComponent(file)}`)
			.concat([
				`filename=files`,
				`siteType=${templateVars.site}`,
				`langType=${templateVars.lang}`,
			])
			.join('&');

		window.open(`/downloadPdf?${query}`, '_blank');
	}

	/**
	 * Показать мокап (пустой мой отчет)
	 */
	showMockup() {
		return new Promise((resolve, reject) => {
			this.$mockup.fadeIn(this.config.animationSpeed, () => {
				resolve();
			});
		});
	}

	/**
	 * Скрыть мокап (пустой мой отчет)
	 */
	hideMockup() {
		return new Promise((resolve, reject) => {
			this.$mockup.fadeOut(this.config.animationSpeed, () => {
				resolve();
			});
		});
	}

	/**
	 * Показать мой отчет
	 */
	showReport() {
		return new Promise((resolve, reject) => {
			this.$myReport.fadeIn(this.config.animationSpeed, () => {
				resolve();
			});
		});
	}

	/**
	 * Скрыть мой отчет
	 */
	hideReport() {
		return new Promise((resolve, reject) => {
			this.$myReport.fadeOut(this.config.animationSpeed, () => {
				resolve();
			});
		});
	}

	/**
	 * Анимирование удаления элементов моего отчета
	 * @param {string} key ключ элемента
	 */
	removeItemWithAnimation(key) {
		let $removeItem = $(`.js-my-report-sortable[data-id="${key}"]`);

		return new Promise((resolve, reject) => {
			$removeItem.slideUp(this.config.animationSpeed, () => {
				$removeItem.remove();

				if ($('.js-my-report-sortable:visible').length === 0) {
					this.hideReport()
						.then(() => this.showMockup())
						.then(() => {
							this.$myReport.triggerHandler('deleted');
							resolve();
						});
				} else {
					resolve();
				}
			});
		});
	}

	/**
	 * Удалить отмеченные страницы моего отчета
	 */
	deletePagesFromReport() {
		const urls = this.getActiveURLs();

		if (urls.length) {
			urls.forEach((url) => {
				this.removeItem(url)
					.then(() => this.removeItemWithAnimation(url))
					.then(() => this.checkCheckboxesState())
					.then(() => this.checkButtonsGroupAccess());
			});

		}
	}
}

AR.waitComponents(['cPreloader_base'], () => {
	const cMyReport_base = new CMyReport_base();
	// Вызов метода со всеми событиями
	cMyReport_base.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cMyReport_base, 'cMyReport_base');

	// Очистка истории на страницах тестов
	if (global.testComponentName && global.testComponentName.indexOf('my-report') < 0) {
		cMyReport_base.cleanMyReport();
	}

	AR.events.on('onPopupStartOpenAnimation', ($popup) => {
		if ($popup.attr('data-popup-id') == 'my-report') {
			let myReportPagesLinks = cMyReport_base.getActiveLinks();

			$('input[name="myReportPages"]').val(JSON.stringify(myReportPagesLinks));
		}
	});
});
