/**
 * Класс реализует базовую логику для компонента Bod
 */
class CBod {
	constructor() {
	}

	/**
	 * Добавить слушателя на событие ресайза
	 * @param {object} callback
	 */
	addResizeListener(callback) {
		$(window).on('resize', callback);
	}

	/**
	 * Elfkbnm слушателя на событие ресайза
	 * @param {object} callback
	 */
	detachResizeListener(callback) {
		$(window).off('resize', callback);
	}

	/**
	 * Скролл к элементу
	 * @param  {object} $object
	 * @return {object}
	 */
	scrollTo($object) {
		// определение позиции смещения экрана сверху по координатам
		const topPos = $object.offset().top;
		let headerHeight = $('.l-layout-wrapper__header').height();
		let menuHeight = $('.b-nav__wrapper').height();

		// скролл html
		$('html')
			// при помощи велосити
			.velocity('scroll', {
				// отступ
				offset: topPos - headerHeight - menuHeight + 'px',
				// продолжительность анимации (берется из конфига)
				duration: this.config.scrollToElementDuration,
				// аппаратное ускорение для мобильных устройств
				mobileHA: this.config.mobileHA
			});
	}

	/**
	 * Изменить хэш страницы
	 * @param {object} $object элемент bod'a
	 */
	setHash($object) {
		// дата-атрибут с хэшом
		const bodHash = $object.data('hash');
		//подстановка хэша
		window.location.hash = bodHash;
	}

	/**
	 * Удалить хэш страницы
	 */
	removeHash() {
		window.location.hash = '!';
	}

	/**
	 * Получить элемент bod'a по хэшу
	 * @param {string} hash хэш
	 */
	getActiveBodItemByHash(hash) {
		return this.$bodMain.filter((i, elem) => $(elem).data('hash') == hash);
	}

	/**
	 * Проверка хэша страницы
	 */
	checkHash() {
		// пустой хэш
		const windowHash = window.location.hash.replace('#', '');

		// если у страницы существует хэш
		if (windowHash) {
			// константа с проверкой соответствия текущего хэша
			const $bodMainActive = this.getActiveBodItemByHash(windowHash);
			// проверка существования элемента
			if ($bodMainActive.length) {
				// вызов раскрытия элемента
				this.open($bodMainActive);
			} else {
				// вызов удаления хэша
				this.removeHash();
			}
		}
	}

	/**
	 * Получить активный элемент bod'a
	 */
	getActiveBodItem() {
		return this.$bodMain.filter('.is-expand');
	}
}

module.exports = CBod;
