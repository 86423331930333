module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'slider/single' && templateVars.config ?
				templateVars.config :
				{
	"name": "single",
	"containerMinHeight": "auto",
	"options": {
		"direction": "horizontal",
		"speed": 1500,
		"loop": false,
		"effect": "slide",
		"fadeEffect": {
			"crossFade": true
		},
		"pagination": {
			"el": ".js-slider-pagination",
			"type": "bullets",
			"bulletElement": "span",
			"clickable": true,
			"render": true
		},
		"navigation": {
			"prevEl": ".js-slider-button--prev",
			"nextEl": ".js-slider-button--next"
		},
		"autoplay": false,
		"initialSlide": 0,
		"simulateTouch": false,
		"keyboard": {
			"enabled": true,
			"onlyInViewport": false
		},
		"mousewheel": true,
		"nested": true,
		"runCallbacksOnInit": false,
		"hashNavigation": true,
		"preventInteractionOnTransition": true,
		"parallax": false
	}
};