const config = require('./config.yaml');
const CSearchForm = require('../../index.js');

/**
 * Реализует логику варианта inner компонента search-form
 */
class CSearchForm_inner extends CSearchForm {
	constructor() {
		super();
		this.config = config;
	}

	/**
	 * Инициализация
	 */
	init() {
		this.$searchForm = $('.js-search--inner');

		if (this.$searchForm.length) {
			this.$searchForm.each((i, elem) => {
				const $form = $(elem);
				const formObj = {
					$form: $form,
					$searchInput: $form.find('.js-search-input'),
					$searchLabel: $form.find('.js-search-label'),
					$searchMessage: $form.find('.js-search-message')
				};

				if (this.config.quickSearch) {
					formObj.$searchResult = formObj.$form.find('.js-search-result');
					formObj.$searchResultList = formObj.$form.find('.js-search-result-list');
					formObj.$searchResultButton = formObj.$form.find('.js-search-result-button');
					this.initQuickSearch(formObj);
				}

				if (this.config.speechRecognition && $('html').hasClass('feature-speechrecognition')) {
					formObj.$searchSpeechButton = formObj.$form.find('.js-search-button-speech');

					this.initSpeechRecognition(formObj);
				}

				formObj.$searchInput
					.on('focus', $.proxy(this, 'onFocusInput', formObj))
					.on('blur', $.proxy(this, 'onBlurInput', formObj));
				formObj.$form.on('submit', $.proxy(this, 'onFormSubmit', formObj));
			});
		}
	}

	/**
	 * Callback на фокус поля ввода
	 * @param {object} formObj объект формы
	 * @param {object} event событие фокуса
	 */
	onFocusInput(formObj, event) {
		this.setFocusState(formObj, event);
	}

	/**
	 * Callback на расфокус поля ввода
	 * @param {object} formObj объект формы
	 * @param {object} event событие расфокуса
	 */
	onBlurInput(formObj, event) {
		this.setBlurState(formObj, event);

		if (this.config.quickSearch && formObj.$searchResult.is(':visible')) {
			this.hideSearchResult(formObj);
		}
	}

	/**
	 * На ввод текста в поле поиска при необходимой длинне запроса
	 * скрыть сообщение о коротком запросе и удалить слушателя события на ввод текста в поле поиска
	 * @param {object} formObj объект формы
	 */
	onInputTyping(formObj) {
		super.onInputTyping(formObj);
	}

	/**
	 * Показать сообщение о том, что запрос слшиком короткий
	 * @param {string} formObj объект формы
	 */
	showMessage(formObj) {
		super.showMessage(formObj);
		$(document).on('click', $.proxy(this, 'onDocumentListenerMessage', formObj));
	}

	/**
	 * Скрыть сообщение о том, что запрос слшиком короткий
	 * @param {string} formObj объект формы
	 */
	hideMessage(formObj) {
		super.hideMessage(formObj);
		$(document).off('click', $.proxy(this, 'onDocumentListenerMessage'));
	}

	/**
	 * Обработчик события клика / тапа / наведения курсора на документ
	 * @param {string} formObj объект формы
	 * @param {object} event событие клика на документ
	 */
	onDocumentListenerMessage(formObj, event) {
		if (!$(event.target).closest(formObj.$form).length) {
			this.hideMessage(formObj);
		}
	}
}

AR.waitComponents([], () => {
	const cSearchForm_inner = new CSearchForm_inner();
	// Вызов метода, инициализирующего все существующие события
	cSearchForm_inner.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cSearchForm_inner, 'cSearchForm_inner');
});
