const CPopup = require('../../index.js');
const config = require('./config.yaml');

/**
 * Класс реализует логику для варианта base компонента popup
 */
class CPopup_base extends CPopup {
	constructor() {
		//вызываем конструктор родителя
		super();

		this.$popups = null;
	}

	/**
	 * Инициализация логики работы компонента
	 */
	init() {
		this.$popups = $('[data-popup-id]');

		this.initCloseButtons();
		this.initTriggers();

		if ($('html').hasClass('browser-ie-9')) {
			this.$popups.addClass('b-popup--js-animation');

		} else {
			this.$popups.addClass(`b-popup--` + config.modalAnimation);
		}
	}

	/**
	 * Провесить слушатель события клика на кнопку "закрыть"
	 */
	initCloseButtons() {
		$.each(this.$popups, (i, popup) => {
			const $popup = $(popup);
			const popupID = $popup.attr('data-popup-id');
			const $closeTrigger = $popup.find('.js-close-trigger');

			if ($closeTrigger.length) {
				$closeTrigger.on('click', () => {
					this.closePopup(popupID);
					return false;
				});
			}
		});
	}

	/**
	 * Провесить слушатель события клика на кнопку открытия popup'a
	 */
	initTriggers() {
		const $triggers = $('[data-popup-trigger-id]');

		$.each($triggers, (i, trigger) => {
			const $trigger = $(trigger);
			const notPreventEvents = this.getNotPreventEvents($trigger.data('popupNotPreventEvent'));

			$trigger.on('click', event => {
				const popupID = $trigger.attr('data-popup-trigger-id');

				if (popupID && this.getPopupByID(popupID)) {
					this.openPopup(popupID);
				}

				if (!(notPreventEvents && notPreventEvents.indexOf(event.type) >= 0)) {
					return false;
				}
			});
		});
	}

	/**
	 * Получить события, которые не нужно превентить (отменять)
	 * @param {string} events события
	 */
	getNotPreventEvents(events) {
		if (events) {
			return events.split(',').map(elem => elem.trim());
		} else {
			return false;
		}
	}

	/**
	 * Получить popup по id
	 * @param {string} id идентификатор popup'a
	 */
	getPopupByID(id) {
		const $popup = this.$popups.filter(`[data-popup-id="${id}"]`);

		return ($popup.length) ? $popup : false;
	}

	/**
	 * Получить открытый popup
	 */
	getCurrentPopup() {
		const $popup = this.$popups.filter(`.is-active`);

		return ($popup.length) ? $popup : false;
	}

	/**
	 * Получить ID открытого popup'a
	 */
	getCurrentPopupID() {
		const $popup = this.getCurrentPopup();

		return ($popup.length) ? $popup.attr('data-popup-id') : false;
	}

	/**
	 * Открыть popup
	 * @param {string} id идентификатор
	 * @param {string} openEffect эффект раскрытия
	 */
	openPopup(id, openEffect = config.modalShowEffect) {
		return new Promise((resolve, reject) => {
			const $popup = this.getPopupByID(id);

			if ($popup) {

				AR.events.emit('onPopupStartOpenAnimation', $popup);

				if ($('html').hasClass('browser-ie-9')) {
					this.lockPage();
					$popup.velocity(openEffect, {
						begin: (el) => {
							$popup.addClass('is-active');
							$(document).on('keydown', $.proxy(this, 'onEscKeyDownListener'));
						},
						complete: (el) => {
							AR.events.emit('onPopupCompleteOpenAnimation', $popup);
							resolve();
						}
					}, config.modalShowSpeed);

				} else {
					this.lockPage();
					$popup
						.addClass('is-active')
						.addClass('md-show');
					$(document).on('keydown', $.proxy(this, 'onEscKeyDownListener'));
				}

			} else {
				reject();
			}
		});
	}

	/**
	 * Закрыть popup
	 * @param {string} id идентификатор
	 * @param {string} closeEffect эффект закрытия
	 */
	closePopup(id, closeEffect = config.modalHideEffect) {
		const $popup = this.getPopupByID(id);

		if ($('html').hasClass('browser-ie-9')) {
			return new Promise((resolve, reject) => {
				if ($popup) {
					this.unlockPage();

					AR.events.emit('onPopupStartCloseAnimation', $popup);

					$popup.velocity(closeEffect, {

						begin: () => {
							$popup.removeClass('is-active');
							$(document).off('keydown', $.proxy(this, 'onEscKeyDownListener'));
						},
						complete: () => {
							AR.events.emit('onPopupCompleteCloseAnimation', $popup);
							resolve();
						}

					}, config.modalHideSpeed);
				} else {
					reject();
				}
			});
		} else {
			$(document).off('keydown', $.proxy(this, 'onEscKeyDownListener'));
			$popup
				.removeClass('is-active')
				.removeClass('md-show');
			this.unlockPage();
		}
	}

	/**
	 * Закблокировать страницу (prevent scroll, add overlay)
	 */
	lockPage() {
		const $body = $('body');
		const $locker = $('#popup-locker');

		if (!$locker.length) {
			$body.addClass('is-lock');

			if (config.modalBlur) {
				$('.l-layout-wrapper').addClass('blur');
			}

			$('<div id="popup-locker" />')
				.appendTo($body)
				.fadeTo(config.modalShowSpeed, config.modalLockerOpacityShow);
		}
	}

	/**
	 * Разблокировать страницу
	 */
	unlockPage() {
		const $body = $('body');
		const $locker = $('#popup-locker');

		if ($locker.length) {

			$locker.fadeTo(config.modalHideSpeed, 0, () => {
				$('#popup-locker').remove();
				$body.removeClass('is-lock');

				if (config.modalBlur) {
					$('.l-layout-wrapper').removeClass('blur');
				}
			});
		}
	}

	/**
	 * Проверка страницы на залоченность
	 */
	isPageLock() {
		const $body = $('body');
		const $locker = $('#popup-locker');

		return Boolean($locker.length && $body.hasClass('is-lock'));
	}

	/**
	 * слушатель нажатия кнопки ESC
	 * @param {object} event событие нажатия на клавишу
	 */
	onEscKeyDownListener(event) {
		// проврека нажатия кнопки ESC
		if (event.keyCode == 27) {
			const currentPopupID = this.getCurrentPopupID();
			this.closePopup(currentPopupID);
		}
	}
}


AR.waitComponents([], () => {
	const cPopup_base = new CPopup_base();
	// Вызов метода со всеми событиями
	cPopup_base.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cPopup_base, 'cPopup_base');
});
