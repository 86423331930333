const config = require('./config.yaml');
const CMobileMenu = require('../../index.js');

/**
 * Реализует логику варианта base компонента verical-accordeon
 */
class CMobileMenu_verticalAccordeon extends CMobileMenu {
	constructor() {
		super();
		this.config = config;
	}

	/**
	 * Инициализация
	 */
	async init() {
		// Меню
		this.$menu = $('.js-mobile-menu--vertical-accordion'); //.js-nav--mobile

		// Если меню на странице существует и необходимо раскрывать и закрывать
		// по клику на управляющий элемент
		if (this.$menu.length) {
			// Все элементы ссылок в меню
			this.$links = this.$menu.find('.js-nav-link');
			// Управляющий элемент
			this.$linkController = this.$menu.find('.js-nav-controller');
			this.$trigger = $('.js-mobile-menu-burger');
			this.$panel = $('.js-mobile-menu-panel');
			this.$header = $('.b-mobile-menu__header');

			this.$navPanel = this.$menu.find('.b-mobile-menu__panel-item');

			this.$body = $('body');
			this.panelScroll = 0;
			this.defaultRelativeTriggerPos = {};
			// Класс списков (выпадашек)
			this.listClass = '.js-nav-list';

			// По клику / тапу на управляющий элемент инициализировать раскрытие
			this.$linkController.on('click', $.proxy(this, 'toggleMenuItem'));

			this.$trigger.on('click', $.proxy(this, 'togglePanel'));
			this.toggleShowOnScroll();
			$(window).on('scroll', $.proxy(this, 'toggleShowOnScroll'));

			// при событии открытия конфига закрывать панель
			AR.events.on('CButtonsPanelInColumnShowList', () => {
				//закрыть меню
				this.closePanel();
				this.closeHeader();
			});

			// Инициализация катсомного скролла
			this.scrollbarInMenu = await this.initScrollbar();
		}
	}


	/**
	 * Установить состояние кнопки наверх
	 */
	toggleShowOnScroll() {
		var scrollHeight = Math.max(
			document.body.scrollHeight, document.documentElement.scrollHeight,
			document.body.offsetHeight, document.documentElement.offsetHeight,
			document.body.clientHeight, document.documentElement.clientHeight
		);

		// кнопочная навигация
		if ($(window).scrollTop() + $(window).height() + $('.l-layout-wrapper__footer.l-footer')[0].clientHeight - scrollHeight > 0) {
			$('.b-pagenav').addClass('bottom');
		} else {
			$('.b-pagenav').removeClass('bottom');
		}

		// бургер
		if ($('#content-area').offset().top - $('.l-header').height() - $(window).scrollTop() > 0) {
			$('.b-mobile-menu__burger').addClass('top');
		} else {
			$('.b-mobile-menu__burger').removeClass('top');
		}

	}

	/**
	 * Получить текущие координаты бургера
	 */
	getTriggerPos() {
		const position = {};

		Object.keys(this.config.triggerPosInPanel).forEach(key => {
			if (this.config.triggerPosInPanel[key]) {
				const scrollTop = $(window).scrollTop();
				let triggerPropValue;

				if (key == 'right' && !this.config.triggerPosInPanel['left']) {
					triggerPropValue = $(window).width() - (this.$trigger[0].getBoundingClientRect().right);
				} else if (scrollTop && key == 'top') {
					triggerPropValue = this.$trigger.offset()[key] - scrollTop;
				} else {
					triggerPropValue = this.$trigger.offset()[key];
				}

				position[key] = triggerPropValue ? triggerPropValue : '';
			}
		});

		return position;
	}

	/**
	 * Получить дефолтное состояние бургера (при закрытой панели меню)
	 */
	getDefaultTriggerPos() {
		const position = {};

		Object.keys(this.config.triggerPosInPanel).forEach(key => {
			position[key] = this.$trigger.css(key);
		});

		return position;
	}

	/**
	 * Получить разницу координат бургера между текущим состоянием (при открытой панели меню)
	 * и дефолтным состоянием (закрытый)
	 */
	getDiffPos() {
		const position = {};
		const relativePos = this.getTriggerPos();

		Object.keys(this.config.triggerPosInPanel).forEach(key => {
			position[key] = relativePos[key] - this.defaultTriggerPos[key];
		});

		return position;
	}

	/**
	 * Переключение состояния списков
	 * @param {Object} event Событие
	 */
	togglePanel(event) {
		const $this = $(event.currentTarget);

		if ($this.hasClass('is-expand')) {
			this.closePanel();
			this.closeHeader();
		} else {
			this.openPanel();
			this.openHeader();
		}
	}

	/**
	 * Оставлять бургер на своем месте при скроле при раскрытой панели меню
	 */
	scrollFreezeBurger() {
		this.panelScroll = this.$panel.scrollTop();
		const burgerTopValue = this.triggerTop - this.panelScroll;
		this.$trigger.css('top', burgerTopValue);
	}

	/**
	 * Открыть панель с меню
	 */
	openPanel() {
		this.$panel
			.addClass('is-expand')
			.velocity(this.config.animation.panel.open.transition, {
				duration: this.config.animation.panel.open.duration,
				begin: () => {
					// this.$body.css('overflow', 'hidden');

					if (this.config.triggerPosInPanel) {
						const triggerPosition = $.extend({}, this.config.triggerPosInPanel);
						this.defaultRelativeTriggerPos = this.getDefaultTriggerPos();
						this.defaultTriggerPos = this.getTriggerPos(this.$trigger);
						Object.keys(triggerPosition).forEach((key) => !triggerPosition[key] && delete triggerPosition[key]);

						if (this.panelScroll && triggerPosition.top) {
							triggerPosition.top = parseFloat(triggerPosition.top) - this.panelScroll;
						}

						this.$trigger
							.css(this.defaultTriggerPos)
							.velocity(triggerPosition, this.config.animation.panel.open.duration);
					}

					this.$trigger.addClass('is-expand');
					$('.js-mobile-menu--vertical-accordion').addClass('is-expand');

					AR.events.emit('onMobileMenuVerticalAccordeonPanelOpenStart', this.$panel);
				},
				complete: () => {
					this.triggerTop = (this.config.triggerPosInPanel.top ? this.$trigger.position().top : 0) + this.panelScroll;
					this.$panel.on('scroll', $.proxy(this, 'scrollFreezeBurger'));

					AR.events.emit('onMobileMenuVerticalAccordeonPanelOpenEnd', this.$panel);
				}
			});
	}

	/**
	 * Закрыть панель с меню
	 */
	closePanel() {
		this.$panel
			.removeClass('is-expand')
			.velocity(this.config.animation.panel.close.transition, {
				duration: this.config.animation.panel.close.duration,
				begin: () => {
					if (this.config.triggerPosInPanel) {
						const position = this.getDiffPos();

						this.$trigger
							.removeClass('is-expand')
							.css(position)
							.velocity(this.defaultRelativeTriggerPos, this.config.animation.panel.close.duration);
						$('.js-mobile-menu--vertical-accordion').removeClass('is-expand');
					} else {
						this.$trigger.removeClass('is-expand');
						$('.js-mobile-menu--vertical-accordion').removeClass('is-expand');
					}

					AR.events.emit('onMobileMenuVerticalAccordeonPanelCloseStart', this.$panel);
				},
				complete: () => {
					this.$trigger
						.velocity('stop', true)
						.removeAttr('style')
						.removeClass('is-expand');
					$('.js-mobile-menu--vertical-accordion').removeClass('is-expand');
					this.$body.css('overflow', '');

					AR.events.emit('onMobileMenuVerticalAccordeonPanelCloseEnd', this.$panel);
				}
			});

		this.$panel.off('scroll', $.proxy(this, 'onScrollFreezeBurger'));
	}

	/**
	 * Открыть Заголовок с меню
	 */
	openHeader() {
		this.$header
			.addClass('is-expand')
			.velocity(this.config.animation.header.open.transition, {
				duration: this.config.animation.header.open.duration,
				begin: () => {
					// this.$body.css('overflow', 'hidden');

					if (this.config.triggerPosInPanel) {
						const triggerPosition = $.extend({}, this.config.triggerPosInPanel);
						this.defaultRelativeTriggerPos = this.getDefaultTriggerPos();
						this.defaultTriggerPos = this.getTriggerPos(this.$trigger);
						Object.keys(triggerPosition).forEach((key) => !triggerPosition[key] && delete triggerPosition[key]);

						if (this.panelScroll && triggerPosition.top) {
							triggerPosition.top = parseFloat(triggerPosition.top) - this.panelScroll;
						}

						this.$trigger
							.css(this.defaultTriggerPos)
							.velocity(triggerPosition, this.config.animation.header.open.duration);
					}

					this.$trigger.addClass('is-expand');
					$('.js-mobile-menu--vertical-accordion').addClass('is-expand');

					AR.events.emit('onMobileMenuVerticalAccordeonPanelOpenStart', this.$header);
				},
				complete: () => {
					this.triggerTop = (this.config.triggerPosInPanel.top ? this.$trigger.position().top : 0) + this.panelScroll;
					this.$header.on('scroll', $.proxy(this, 'scrollFreezeBurger'));

					AR.events.emit('onMobileMenuVerticalAccordeonPanelOpenEnd', this.$header);
				}
			});
	}

	/**
	 * Закрыть Заголовок с меню
	 */
	closeHeader() {
		this.$header
			.removeClass('is-expand')
			.velocity(this.config.animation.header.close.transition, {
				duration: this.config.animation.header.close.duration,
				begin: () => {
					if (this.config.triggerPosInPanel) {
						const position = this.getDiffPos();

						this.$trigger
							.removeClass('is-expand')
							.css(position)
							.velocity(this.defaultRelativeTriggerPos, this.config.animation.header.close.duration);
						$('.js-mobile-menu--vertical-accordion').removeClass('is-expand');
					} else {
						this.$trigger.removeClass('is-expand');
						$('.js-mobile-menu--vertical-accordion').removeClass('is-expand');
					}

					AR.events.emit('onMobileMenuVerticalAccordeonPanelCloseStart', this.$header);
				},
				complete: () => {
					this.$trigger
						.velocity('stop', true)
						.removeAttr('style')
						.removeClass('is-expand');
					$('.js-mobile-menu--vertical-accordion').removeClass('is-expand');
					this.$body.css('overflow', '');

					AR.events.emit('onMobileMenuVerticalAccordeonPanelCloseEnd', this.$header);
				}
			});

		this.$header.off('scroll', $.proxy(this, 'onScrollFreezeBurger'));
	}

	/**
	 * Инициализация скроллбара
	 */
	initScrollbar() {
		return AR.components.cScrollbar_base.initScrollbar(this.$menu.find('.js-menu-scrollbar'));
	}

	/**
	 * Блокировка
	 */
	lock() {
		const scrollWidth = window.innerWidth - document.body.clientWidth;

		this.scrollTop = $(document).scrollTop();
		this.$body
			.css('margin-right', `${scrollWidth}px`)
			.addClass('is-locked');
		this.$navPanel.css('width', '');
	}

	/**
	 * Разблокировка
	 */
	unlock() {
		const scrollWidth = window.innerWidth - document.body.clientWidth;

		this.$body
			.css('margin-right', '')
			.removeClass('is-locked');
		this.$navPanel.css('width', `calc(100% + ${scrollWidth}px)`);
		$(document).scrollTop(this.scrollTop);
	}

	/**
	 * Переключение состояния списков
	 * @param {Object} event Событие
	 */
	toggleMenuItem(event) {
		const $this = $(event.currentTarget);

		// Если списки закрываемы и список у текущего пункта не раскрыт
		if ($this.hasClass('is-expand')) {
			event.preventDefault();
			if (this.config.hideInnerOnClose) {
				// Раскрытые списки внутри текущего
				const $expandedElements = $this
					.closest('.js-nav-item')
					.find(`.js-nav-controller.is-expand`);
				// Закрыть списки
				this.closeMenuItem($expandedElements, true);
			}
			// Закрыть список
			this.closeMenuItem($this);
		} else if (!$this.hasClass('is-expand')) {
			event.preventDefault();

			// Если необходимо закрывать список на текщуем уровне при открытии другого
			if (this.config.hidePreviousListOnExpand) {
				// Раскрытые списки на текущем уровне
				const $expandedElements = $this
					.closest('.js-nav-item')
					.siblings('.js-nav-item')
					.find(`.js-nav-controller.is-expand`);
				// Закрыть списки
				this.closeMenuItem($expandedElements, true);
			}

			// Открыть список
			this.openMenuItem($this, event);
		}
	}

	/**
	 * Закрытие списка
	 * @param {Object}  $element Контроллер
	 */
	openMenuItem($element) {
		$element
			.addClass('is-expand')
			.siblings('.js-nav-link')
			.addClass('is-expand')
			.closest('.js-nav-item')
			.children(this.listClass)
			.addClass('is-expand')
			.velocity(this.config.animation.menuItem.open.transition, {
				duration: this.config.animation.menuItem.open.duration,
				// Стригерить глобальное событие начала раскрытия списка
				begin: (element) => AR.events.emit('onMobileMenuVerticalAccordeonOpenStart', $element),
				// Стригерить глобальное событие завершения раскрытия списка
				complete: (element) => AR.events.emit('onMobileMenuVerticalAccordeonOpenEnd', $element)
			});
	}

	/**
	 * Закрытие списка
	 * @param {Object}  $element     Контроллер
	 * @param {Boolean} closeForNext Делается ли закрытие списка перед открытием следующего
	 */
	closeMenuItem($element, closeForNext) {
		$element
			.removeClass('is-expand')
			.siblings('.js-nav-link')
			.removeClass('is-expand')
			.closest('.js-nav-item')
			.children(this.listClass)
			.removeClass('is-expand')
			.velocity(this.config.animation.menuItem.close.transition, {
				duration: this.config.animation.menuItem.close.duration,
				// Стригерить глобальное событие начала закрытия списка
				begin: (element) => AR.events.emit('onMobileMenuVerticalAccordeonCloseStart', $element),
				// Стригерить глобальное событие завершения закрытия списка
				complete: (element) => AR.events.emit('onMobileMenuVerticalAccordeonCloseEnd', $element)
			});
	}
}

AR.waitComponents(['cScrollbar_base'], () => {
	const cMobileMenu_verticalAccordeon = new CMobileMenu_verticalAccordeon();
	// Вызов метода, инициализирующего все существующие события
	cMobileMenu_verticalAccordeon.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cMobileMenu_verticalAccordeon, 'cMobileMenu_verticalAccordeon');
});
