module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'breadcrumbs/short' && templateVars.config ?
				templateVars.config :
				{
	"titleParam": [
		"shortTitle",
		"titleById"
	],
	"typeOfshortening": "leftToRight",
	"dontRollUpFirst": true,
	"tooltipTitle": false
};