const cookie = require('cookie_js');
const text = require('./text.yaml')[templateVars.lang];
const config = require('./config.yaml');
const isElementInView = require('component/core/.variant/base/additionals/isElementInView.js');
const CTour = require('../../index.js');

/**
 * Класс реализует логику варианта base для компонента tour
 */
class CTour_base extends CTour {
	constructor() {
		super();
		this.config = config;
		this.tours = this.config.tours[templateVars.lang];
	}

	/**
	 * Инициализация
	 */
	init() {
		this.hopscotch = false;
		this.tourQueue = this.makeToursQueue(this.tours);

		if (this.tourQueue.length && $(window).width() > 768) {
			this.addLockLayout();

			if (Object.keys(AR.components.cNotification_base.notifications).length) {
				AR.events.on('onNotificationRemove', () => {
					if (!Object.keys(AR.components.cNotification_base.notifications).length) {
						this.startToursQueueItem();
					}
				});

			} else {
				this.startToursQueueItem();
			}
		}
	}

	/**
	 * Создать тур
	 * @param {object} tourConfig конфиг тура
	 */
	createTour(tourConfig) {
		const result = {};

		result.cookieName = this.getCookieName(tourConfig.id);
		result.config = tourConfig;

		result.config.i18n = text;
		result.config.onStart = () => {
			this.setTourPlayingStatus(true);
			const stepNum = this.hopscotch.getCurrStepNum();
			const stepConfig = result.config.steps[stepNum];
			const $stepTarget = $(stepConfig.target);
			const $tourContainer = $(`.hopscotch-bubble.tour-${result.config.id}`);

			if ($tourContainer.length && stepConfig.additionaClasses) {
				$tourContainer.addClass(stepConfig.additionaClasses);
			}

			if (stepConfig.hideSource) {
				$stepTarget.css({
					visibility: 'hidden',
				});
			}

			this.setTourStatus('tourStart', result.config.id, templateVars.lang);
		};

		result.config.onShow = () => {
			if (this.config.skipButton.show) {
				const $skipButton = this.appendSkipButton();

				$skipButton.on('click', () => {
					this.skipTours();
				});
			}

			const stepNum = this.hopscotch.getCurrStepNum();
			const stepConfig = result.config.steps[stepNum];
			const $tourContainer = $(`.hopscotch-bubble.tour-${result.config.id}`);

			if ($tourContainer.length && stepConfig.placement) {
				$tourContainer.addClass(stepConfig.placement);
			}

			if ($tourContainer.length && stepConfig.additionaClasses) {
				$tourContainer.addClass(stepConfig.additionaClasses);
			}

			if (!this.itemIsHasScrollBar($('body'))) {
				$tourContainer.addClass('global-vertical-scroll-is-not-exist');
			}

			if ($('html').hasClass('browser-safari')) {
				$tourContainer.removeClass('global-vertical-scroll-is-not-exist');
			}

			if (result.config.lockPage) {
				this.lockPage();
			}

			if (stepConfig.hideSource) {
				$stepTarget.css({
					visibility: 'hidden',
				});
			}
		};

		result.config.onEnd = () => {
			this.setTourTrue(result.cookieName);
		};

		return result;
	}

	/**
	 * Создать очередь туров
	 * @param {object} tours туры
	 */
	makeToursQueue(tours) {
		let queue = [];

		for (let i in tours) {
			const tour = tours[i];
			const $targetItem = $(tour.steps[0].target);
			const cookieName = this.getCookieName(tour.id);

			if ($targetItem.length && !cookie(cookieName)) {
				queue.push(tour);
			}
		}

		return queue;
	}

	/**
	 * Получить имя для куков
	 * @param {string} id идентификатор тура
	 */
	getCookieName(id) {
		return `tourPassed_${id}_${templateVars.lang}`;
	}

	/**
	 * Запустить тур из очереди туров
	 */
	startToursQueueItem() {
		const firstItemFromQueue = this.tourQueue[0];
		const currentIndexItem = 0;
		const nextItemIndex = 1;

		if (!firstItemFromQueue) {
			return false;
		}

		for (let i in firstItemFromQueue.steps) {
			const step = firstItemFromQueue.steps[i];
			const $stepTarget = $(step.target);
			const $stepHtml = $($.parseHTML(step.content));
			const $grabZone = $stepHtml.find('.js-grabbed-content');

			if (step.grabContent) {
				if ($grabZone) {
					$stepTarget
						.clone()
						.appendTo($grabZone);
				}

				step.content = $stepHtml[0].outerHTML;
			}
		}

		firstItemFromQueue.onClose = () => {
			this.setTourPlayingStatus(false);

			const cookieName = this.getCookieName(firstItemFromQueue.id);
			this.setTourTrue(cookieName);

			if (typeof this.tourQueue[nextItemIndex] !== 'undefined') {
				const nextFirstItemFromQueue = this.tourQueue[nextItemIndex];

				for (let i in nextFirstItemFromQueue.steps) {
					const $stepTarget = $(nextFirstItemFromQueue.steps[i].target);

					if (nextFirstItemFromQueue.steps[i].hideSource) {
						$stepTarget.css({
							visibility: 'visible',
						});
					}
				}

				if (!nextFirstItemFromQueue.lockPage) {
					this.unlockPage();
				}

				this.tourQueue = this.tourQueue.splice(1);

				this.startToursQueueItem();
			} else {
				for (let i in this.tourQueue[currentIndexItem].steps) {
					const $stepTarget = $(this.tourQueue[currentIndexItem].steps[i].target);

					if (this.tourQueue[currentIndexItem].steps[i].hideSource) {
						$stepTarget.css({
							visibility: 'visible',
						});
					}
				}

				this.unlockPage();

				this.tourQueue = this.tourQueue.splice(1);
				$(window).trigger('tourEnd');
			}
		};

		const tour = this.createTour(firstItemFromQueue);

		setTimeout(() => {
			this.start(tour);
		}, 100);
	}

	setTourPlayingStatus(on = false) {
		global[`tour-playing`] = on;
	}

	/**
	 * Начать тур по странице
	 */
	start(tour) {
		if (!this.hopscotch) {
			require.ensure(['hopscotch'], require => {
				this.hopscotch = require('hopscotch');
				this.checkOnInViewStart(tour);
				// hopscotch.startTour(this.configPart);
			});
		} else if (this.hopscotch.getCurrStepNum() !== 'undefined') {
			this.hopscotch.endTour(false);
			setTimeout(() => {
				this.checkOnInViewStart(tour);
			}, 100);
		} else {
			this.checkOnInViewStart(tour);
		}
	}

	/**
	 * Задать статус тура
	 * @param {string} status статус
	 * @param {string} id иденификатор тура
	 * @param {string} lang языковая версия сайта
	 */
	setTourStatus(status, id, lang) {
		global[`${status}_${id}_${lang}`] = true;
		$(window).trigger(status, [id, lang]);
	}

	/**
	 * Тур показывается в текущее время
	 */
	isTourPlaying() {
		return global[`tour-playing`];
	}

	/**
	 * У элемента есть скролл
	 * @param {object} $item
	 */
	itemIsHasScrollBar($item) {
		return $item.get(0).scrollHeight > $item.get(0).clientHeight;
	}

	/**
	 * Пропустить все туры в очереди
	 */
	skipTours() {
		this.tourQueue.forEach(elem => {
			const cookieName = this.getCookieName(elem.id);
			this.setTourTrue(cookieName);
		});

		if (this.hopscotch) {
			this.hopscotch.endTour(false);
		}

		this.unlockPage();
		this.tourQueue = [];
	}

	/**
	 * Добавить кнопку пропуска всех туров находящихся в очереди
	 */
	appendSkipButton() {
		const $hopscotchActions = $('.hopscotch-actions');
		const template = this.config.skipButton.template;
		const localizedTemplate = template.replace('${skipButtonText}', text.skipBtn);
		const $skipButton = $(localizedTemplate);

		if (!$hopscotchActions.find($skipButton).length) {
			$hopscotchActions.append($skipButton);
			return $skipButton;
		} else {
			return $hopscotchActions.find($skipButton);
		}
	}

	/**
	 * Задать куки для тура, что он был показан
	 * @param {*} name
	 */
	setTourTrue(name) {
		cookie.set(name, 'true', {
			expires: 365,
			path: '/'
		});
	}

	/**
	 * Проверить, что элемент тура видим в окне и запустить тур
	 * Если не в области видимости, то скролить к нему и запустить тур
	 * @param {*} tour
	 */
	checkOnInViewStart(tour) {
		let stepNum = typeof this.hopscotch.getCurrStepNum() !== 'undefined' ? this.hopscotch.getCurrStepNum() : 0;
		const stepConfig = tour.config.steps[stepNum];
		const $targetElem = $(stepConfig.target);

		if ($targetElem.length && isElementInView($targetElem)) {
			cookie.set(tour.cookieName, 'in-process', {
				expires: 365,
				path: '/'
			});

			return this.hopscotch.startTour(tour.config);
		} else {
			$('html').velocity('scroll', {
				offset: 0,
				complete: () => {
					cookie.set(tour.cookieName, 'in-process', {
						expires: 365,
						path: '/'
					});

					return this.hopscotch.startTour(tour.config);
				}
			});
		}
	}

	/**
	 * Начать тур по странице единожды (если тур уже был показан, то не показывать ещё раз)
	 */
	startFirstTime(tour) {
		if (cookie(tour.cookieName) !== 'true') {
			if (!this.tourQueue.length) {
				this.tourQueue.push(tour.config);
				this.startToursQueueItem();
			} else {
				let isTourExist = false;

				for (let i in this.tourQueue) {
					if (this.tourQueue[i].id == tour.config.id) {
						isTourExist = true;
						break;
					}
				}

				if (!isTourExist) {
					this.tourQueue.push(tour.config);

					if (!this.isTourPlaying() && !this.tourQueue) {
						this.startToursQueueItem();
					}
				}
			}
		} else {
			this.setTourStatus('tourSkipped', tour.config.id, templateVars.lang);
		}
	}

	/**
	 * Проверить наличие куков для текущего тура
	 */
	checkCookie(tour) {
		return cookie(tour.cookieName);
	}

	/**
	 * Заблокировать страницу
	 */
	lockPage() {
		const $lockLayout = $('#tour-lock-layout');

		if ($lockLayout.length && !this.isLockPage()) {
			$lockLayout.addClass('is-active');
			$lockLayout.velocity('fadeIn', 1000);
		}
	}

	/**
	 * Заблокировать страницу
	 */
	unlockPage() {
		const $lockLayout = $('#tour-lock-layout');

		if ($lockLayout.length) {
			$lockLayout.removeClass('is-active');
			$lockLayout.velocity('fadeOut', 1000);
		}
	}

	/**
	 * Разблокировать страницу
	 */
	isLockPage() {
		const $lockLayout = $('#tour-lock-layout');

		if ($lockLayout.length) {
			return $lockLayout.hasClass('is-active');
		} else {
			return false;
		}
	}

	/**
	 * Добавить на страницу скрытый блок, который будет являтся оверлеем (блокировать страницу) при туре
	 */
	addLockLayout() {
		$('#layout-wrapper').append('<div class="tour-lock-layout" id="tour-lock-layout"></div>');
	}
}

AR.waitComponents(['cNotification_base', 'cCookieLaw_double'], () => {
	const cTour_base = new CTour_base;
	// Вызов метода со всеми событиями
	cTour_base.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cTour_base, 'cTour_base');
});
