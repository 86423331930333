import config from './config.yaml';
import CSlider from '../../index.js';

/**
 * Реализует логику варианта single компонента slider
 */
class CSlider_single extends CSlider {
	constructor() {
		super();
		this.config = config;
	}

	/**
	 * Инициализация
	 */
	init() {
		this.sliderWrapper = document.querySelector(`.js-slider-wrapper--${config.name}`);

		if (this.sliderWrapper) {
			this.initSlider(this.sliderWrapper);
		}
	}
}

AR.waitComponents(['cPreloader_base'], () => {
	const cSlider_single = new CSlider_single();
	// Вызов метода, инициализирующего все существующие события
	cSlider_single.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cSlider_single, 'cSlider_single');
});
