module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'tracking/base' && templateVars.config ?
				templateVars.config :
				{
	"printOnlyGoogle": false,
	"systems": {
		"ar": {
			"ru": {
				"yandex": {
					"id": 66393139,
					"trackGoals": true,
					"userVisitedGoalID": "userVisited"
				}
			}
		}
	}
};