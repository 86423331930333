module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'logo/base' && templateVars.config ?
				templateVars.config :
				{
	"expandedWidth": false,
	"animationSpeed": 200,
	"useAs": "template",
	"logo": {
		"ru": "../../images/logo-ru.svg",
		"en": "../../images/logo-en.svg"
	},
	"text": {
		"ru": "Путеводитель акционера 2024"
	}
};