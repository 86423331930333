class CMainpage {
	constructor() {
		this.animated = false;
	}

	async init() {
		const wrapper = $('.mainpage-wrapper');
		const bg = $('');
		const mainpage = this;

		if (wrapper.length) {
			const bgImage = new Image();
			bgImage.src = '/images/components/mainpage/images/main.jpg';

			function checkLoaded() {
				return new Promise((resolve, reject) => {
					setTimeout(helperFunction, 10);

					function helperFunction() {
						if (bgImage.complete) {
							resolve();
						} else {
							setTimeout(helperFunction, 10);
						}
					}
				});
			}

			checkLoaded().then(() => {
				$('.mainpage-wrapper').removeClass('preloader');
				mainpage.animate();
			});

			$('.mainpage-wrapper').ready(function () {
				$('.mainpage-wrapper').removeClass('preloader');
				mainpage.animate();
			});
		}
	}

	animate() {
		const wrapper = $('.mainpage-wrapper');
		const animationDelay = 0;
		const animationStep = 500;

		if (wrapper.length && !this.animated) {
			var currentStep = 0;

			var h1 = $('.mainpage-wrapper h1');
			var h2 = $('.mainpage-wrapper h2');
			var span = $('.mainpage-wrapper span');
			var links = $('.mainpage-wrapper a');
			var logo = $('.mainpage-wrapper .mainpage__logo .mp-animated');

			$(links).attr('onclick', 'return false;');
			$(links).addClass('link-inactive');

			setTimeout(() => {
				$(logo).addClass('mp-animated--active');
			}, animationDelay + animationStep * currentStep++);

			setTimeout(() => {
				$(h1).addClass('mp-animated--active');
			}, animationDelay + animationStep * currentStep++);

			setTimeout(() => {
				$(h2).addClass('mp-animated--active');
			}, animationDelay + animationStep * currentStep++);

			setTimeout(() => {
				$(span).addClass('mp-animated--active');
			}, animationDelay + animationStep * currentStep++);

			setTimeout(() => {
				setTimeout(() => {
					AR.components.cPopup_base.openPopup('mainpage-popup', 'transition.bounceDownIn');
					$(links).attr('onclick', '');
					$(links).removeClass('link-inactive');
				}, 800);

				$(links).each(function () {
					$(this).addClass('mp-animated--active');
				});
			}, animationDelay + animationStep * currentStep++);
		}

		this.animated = true;
	}
}

AR.waitComponents(['cPreloader_base', 'cPopup_base'], () => {
	const cMainpage = new CMainpage();
	// Вызов метода, инициализирующего все существующие события
	cMainpage.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cMainpage, 'cMainpage');
});
