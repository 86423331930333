module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'bod/bod-animated-blocks' && templateVars.config ?
				templateVars.config :
				{
	"scrollToElementDuration": 500,
	"bodContentToggleAnimationDuration": 200,
	"directorsPerRow": 3,
	"gridClass": "col-4 col-md-6 col-xs-12",
	"directors": [
		{
			"photo": "/images/tzar_bod.jpeg",
			"name": "BOD&nbsp;Director Name1",
			"post": "BOD Director post1",
			"static": true,
			"position": "start",
			"text": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>"
		},
		{
			"photo": "/images/tzar_bod.jpeg",
			"name": "BOD Director Name2",
			"post": "BOD Director post2",
			"text": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>"
		},
		{
			"photo": "/images/tzar_bod.jpeg",
			"name": "BOD Director Name3",
			"post": "BOD Director post3",
			"text": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>"
		}
	]
};