module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'social-services/static-in-line' && templateVars.config ?
				templateVars.config :
				{
	"base": {
		"animationClose": "transition.slideDownOut",
		"animationCloseDuration": 200,
		"animationOpen": "transition.slideUpIn",
		"animationOpenDuration": 200,
		"links": {
			"ru": [
				{
					"modifier": "telegram",
					"targetBlank": true,
					"url": "https://t.me/ctrlpack",
					"text": "Telegram"
				},
				{
					"modifier": "vkontakte",
					"targetBlank": true,
					"url": "https://vk.com/ksavtb",
					"text": "VKontakte"
				},
				{
					"modifier": "ok",
					"targetBlank": true,
					"url": "https://ok.ru/vtb",
					"text": "Odnoklassniki"
				}
			],
			"en": [
				{
					"modifier": "facebook1",
					"targetBlank": true,
					"url": "https://www.facebook.com/vtbgroup/",
					"text": "Facebook"
				},
				{
					"modifier": "twitter",
					"targetBlank": true,
					"url": "https://twitter.com/VTB",
					"text": "Twitter"
				},
				{
					"modifier": "vkontakte",
					"targetBlank": true,
					"url": "https://vk.com/ksavtb",
					"text": "VKontakte"
				},
				{
					"modifier": "instagram",
					"targetBlank": true,
					"url": "https://www.instagram.com/bankvtb/",
					"text": "Instagram"
				},
				{
					"modifier": "ok",
					"targetBlank": true,
					"url": "https://ok.ru/vtb",
					"text": "Odnoklassniki"
				}
			]
		}
	},
	"shareholders": {
		"animationClose": "transition.slideDownOut",
		"animationCloseDuration": 200,
		"animationOpen": "transition.slideUpIn",
		"animationOpenDuration": 200,
		"links": {
			"ru": [
				{
					"modifier": "facebook1",
					"targetBlank": true,
					"url": "https://www.facebook.com/ksavtb",
					"text": "Facebook"
				},
				{
					"modifier": "twitter",
					"targetBlank": true,
					"url": "https://twitter.com/ksavtb",
					"text": "Twitter"
				}
			],
			"en": [
				{
					"modifier": "facebook1",
					"targetBlank": true,
					"url": "https://www.facebook.com/ksavtb",
					"text": "Facebook"
				},
				{
					"modifier": "twitter",
					"targetBlank": true,
					"url": "https://twitter.com/ksavtb",
					"text": "Twitter"
				}
			]
		}
	}
};