module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'slider/gallery' && templateVars.config ?
				templateVars.config :
				{
	"name": "gallery",
	"containerMinHeight": "auto",
	"options": {
		"direction": "horizontal",
		"speed": 1500,
		"loop": false,
		"effect": "slide",
		"fadeEffect": {
			"crossFade": true
		},
		"navigation": {
			"prevEl": ".js-slider-button--prev",
			"nextEl": ".js-slider-button--next"
		},
		"autoplay": false,
		"initialSlide": 0,
		"simulateTouch": false,
		"keyboard": {
			"enabled": true,
			"onlyInViewport": false
		},
		"mousewheel": false,
		"nested": true,
		"runCallbacksOnInit": false,
		"hashNavigation": false,
		"preventInteractionOnTransition": true,
		"parallax": false
	}
};