const CPreloader = require('../../index.js');

/**
 * Реализует логику варианта base компонента preloader
 */
class CPreloader_base extends CPreloader {
	constructor() {
		super();
	}

	/**
	 * Включить прелоадер у элемента
	 */
	enablePreloaderInItem($elements) {
		return $elements.each((i, elem) => {
			const $element = $(elem);
			const element = $element[0];

			if ($element.hasClass('preloader')) {
				return false;
			}

			let classList = ['preloader'];

			const positionCssProp = this.getCssProps($element, ['position']);
			switch (positionCssProp.position) {
				case 'absolute':
				case 'fixed':
					classList.push('preloader--is-flow-item');
					break;
			}

			const tagName = $element.prop('tagName').toLowerCase();
			switch (tagName) {
				case 'button':
					classList.push('preloader--is-button');
					$element.attr('disabled', true);
					break;
			}

			$element.addClass(classList.join(' '));
		});
	}

	/**
	 * Отключить прелоадер у элемента
	 */
	disablePreloaderInItem($elements) {
		return $elements.each(function () {
			const $element = $(this);

			$element
				.removeClass('preloader')
				.removeClass('preloader--is-flow-item')
				.removeClass('preloader--is-button');

			const tagName = $element.prop('tagName').toLowerCase();
			switch (tagName) {
				case 'button':
					$element.attr('disabled', false);
					break;
			}
		});
	}

	/**
	 * Берет стили у элемента по маске
	 * Метод легко расширяем под дефолтный забор любых свойств
	 * Также можно передавать свой набор необхоимых свойств
	 * @param $item
	 * @param props - передаваемый массив параметров
	 * @returns {{}}
	 */
	getCssProps($item, props = []) {
		let result = {},
			propsMap = [
				'border-top-style',
				'border-top-color',
				'border-bottom-style',
				'border-bottom-color',
				'border-left-style',
				'border-left-color',
				'border-right-style',
				'border-right-color',
				'background-color',
				'vertical-align',
				'text-align',
				'white-space',
				'font-weight',
				'color',
				'font-size',
				'text-decoration-line',
				'font-style',
				'display',
				'position'
			];

		if (props.length) {
			propsMap = props.filter(function (i) {
				return propsMap.indexOf(i) !== -1;
			});
		}

		return $item.css(propsMap);
	}
}

AR.waitComponents([], () => {
	const cPreloader_base = new CPreloader_base();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cPreloader_base, 'cPreloader_base');
});
