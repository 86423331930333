const config = require('./config.yaml');
const CMenu = require('../../index.js');

/**
 * Реализует логику варианта vertical-accordeon компонента menu
 */
class CMenu_verticalAccordeon extends CMenu {
	constructor() {
		super();
		this.config = config;
		// Тип меню для определения класса
		this.menuType = 'left';
		// Имя варианта для создания глобальных событий
		this.eventNameVariantPart = 'VerticalAccordeon';
	}

	/**
	 * Инициализация
	 */
	init() {
		// Меню
		this.$menu = $('.js-left-nav--vertical-accordeon');

		// Если меню на странице существует и необходимо раскрывать и закрывать
		// по клику на управляющий элемент
		if (this.$menu.length && (this.config.expandable || this.config.closable)) {
			// Все элементы ссылок в меню
			this.$links = this.$menu.find('.js-left-link');
			// Управляющий элемент
			this.$linkController = this.$menu.find('.js-icon-left-nav');
			// Класс списков (выпадашек)
			this.listClass = '.js-left-list';

			// По клику / тапу на управляющий элемент инициализировать раскрытие
			this.$linkController.on('click touchstart', $.proxy(this, 'toggle'));

			// Если необходимо закрывать списки по клику на документ и есть раскрытие списки
			if (this.config.documentClickClose && this.$links.hasClass('is-expand')) {
				// Провесить слушателя клика по документу
				$(document).on('click touchstart', $.proxy(this, 'onDocumentListener'));
				this.documentClickListen = true;
			}

			// Если меню необходимо делать фиксируемым
			if (this.config.fixedMenu) {
				// Инициализировать наблюдателя состояния фиксирующегося меню
				this.initFixMenuWatcher();
			}
		}
	}

	/**
	 * Переключение состояния списков
	 * @param  {Object} event Событие
	 */
	toggle(event) {
		const $this = $(event.currentTarget);
		// Элемент ссылки (пункт), в котором находится управляющий элемент
		const $thisLink = $this.closest('.js-left-link');

		// Если списки закрываемы и список у текущего пункта не раскрыт
		if (this.config.closable && $thisLink.hasClass('is-expand')) {
			event.preventDefault();

			if (this.config.hideInnerOnClose) {
				// Раскрытые списки внутри текущего
				const $expandedElements = $this
					.closest('.js-left-item')
					.find(`.js-${this.menuType}-link.is-expand`);
				// Закрыть списки
				this.close($expandedElements, true);
			}

			// Закрыть список
			this.close($thisLink);
		} else if (this.config.expandable && !$thisLink.hasClass('is-expand')) {
			event.preventDefault();

			// Если необходимо закрывать список на текщуем уровне при открытии другого
			if (this.config.hidePreviousListOnExpand) {
				// Раскрытые списки на текущем уровне
				const $expandedElements = $this
					.closest('.js-left-item')
					.siblings('.js-left-item')
					.find(`.js-${this.menuType}-link.is-expand`);
				// Закрыть списки
				this.close($expandedElements, true);
			}

			// Открыть список
			this.open($thisLink, event);
		}
	}

	/**
	 * Открытие списка
	 * @param  {Object}  $element Элемент список
	 */
	open($element) {
		// Эффект и продолжительность анимации
		const {openEffect, openDuration} = this.getOpenAnimation();

		// Если необходимо закрывать списки по клику на документ и клик по документу не слушается
		if (this.config.documentClickClose && !this.documentClickListen) {
			// Провесить слушателя клика по документу
			$(document).on('click touchstart', $.proxy(this, 'onDocumentListener'));
			this.documentClickListen = true;
		}

		$element
			.addClass('is-expand')
			.closest('.js-left-item')
			.children(this.listClass)
			.velocity(openEffect, {
				duration: openDuration,
				// Стригерить глобальное событие начала раскрытия списка
				begin: (element) => AR.events.emit(`onMenu${this.eventNameVariantPart}OpenStart`, $(element)),
				// Стригерить глобальное событие завершения раскрытия списка
				complete: (element) => AR.events.emit(`onMenu${this.eventNameVariantPart}OpenEnd`, $(element))
			});
	}

	/**
	 * Закрытие списка
	 * @param  {Object}  $element      Элемент список
	 * @param  {Boolean} closeForNext  Делается ли закрытие списка перед открытием следующего
	 */
	close($element, closeForNext) {
		// Эффект и продолжительность анимации
		const {closeEffect, closeDuration} = this.getCloseAnimation();

		$element
			.removeClass('is-expand')
			.closest('.js-left-item')
			.children(this.listClass)
			.velocity(closeEffect, {
				duration: closeDuration,
				// Стригерить глобальное событие начала закрытия списка
				begin: (element) => AR.events.emit(`onMenu${this.eventNameVariantPart}CloseStart`, $(element)),
				// Стригерить глобальное событие завершения закрытия списка
				complete: (element) => AR.events.emit(`onMenu${this.eventNameVariantPart}CloseEnd`, $(element))
			});

		// Если необходимо закрывать списки по клику на документ
		// и слушатель клика по документу провешен
		// и у всех пунктов списки не раскрыты
		// и закрытие делается не перед открытием следующего
		if (this.config.documentClickClose && this.documentClickListen
			&& !this.$links.hasClass('is-expand') && !closeForNext) {
			// Снять слушателя клика по документу
			$(document).off('click touchstart', $.proxy(this, 'onDocumentListener'));
			this.documentClickListen = false;
		}
	}

	/**
	 * Получение эффекта и продолжительности анимации раскрытия
	 * @param  {Object} $element Элемент пункт
	 * @return {Object}          Эффект и время анимации
	 */
	getOpenAnimation() {
		return {
			openEffect: this.config.animation.open.transition,
			openDuration: this.config.animation.open.duration
		};
	}

	/**
	 * Получение эффекта и продолжительности анимации закрытия
	 * @param  {Object}  $element     Элемент пункт
	 * @return {Object}               Эффект и время анимации
	 */
	getCloseAnimation() {
		return {
			closeEffect: this.config.animation.close.transition,
			closeDuration: this.config.animation.close.duration
		};
	}

	/**
	 * Обработчик события клика / тапа / наведения курсора на документ
	 * Создаем свой обработчик вместо унаследованного,
	 * иначае при нахождении на странице нескольких вариантов, которые также унаследовали метод
	 * и для одного из них сделать $.off(), то для всех остальных также отключаюется, т.е наследуют
	 * @param  {Object} event Событие
	 */
	onDocumentListener(event) {
		// Обращаемся к родительскому методу
		super.onDocumentListener(event);
	}
}

AR.waitComponents([], () => {
	const cMenu_verticalAccordeon = new CMenu_verticalAccordeon();
	// Вызов метода, инициализирующего все существующие события
	cMenu_verticalAccordeon.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cMenu_verticalAccordeon, 'cMenu_verticalAccordeon');
});
