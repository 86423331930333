module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'menu/vertical-accordeon' && templateVars.config ?
				templateVars.config :
				{
	"titleParam": [
		"menuTitle",
		"shortTitle",
		"titleById"
	],
	"depthLevel": 4,
	"expandable": true,
	"closable": true,
	"documentClickClose": true,
	"highlightOnlyCurrent": false,
	"hidePreviousListOnExpand": true,
	"hideInnerOnClose": true,
	"fixedMenu": false,
	"animation": {
		"open": {
			"transition": "slideDown",
			"duration": 200
		},
		"close": {
			"transition": "slideUp",
			"duration": 200
		}
	}
};