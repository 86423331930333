module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'mobile-menu/vertical-accordeon' && templateVars.config ?
				templateVars.config :
				{
	"titleParam": "shortTitle",
	"expandCurrentOnLoad": false,
	"depthLevel": 4,
	"hideInnerOnClose": false,
	"hidePreviousListOnExpand": false,
	"trackerParamsKeyConfig": "topMenu",
	"triggerPosInPanel": {
		"top": null,
		"right": null
	},
	"animation": {
		"menuItem": {
			"open": {
				"transition": "slideDown",
				"duration": 200
			},
			"close": {
				"transition": "slideUp",
				"duration": 200
			}
		},
		"panel": {
			"open": {
				"transition": "transition.slideRightBigIn",
				"duration": 250
			},
			"close": {
				"transition": "transition.slideRightBigOut",
				"duration": 250
			}
		},
		"header": {
			"open": {
				"transition": "transition.slideRightBigIn",
				"duration": 250
			},
			"close": {
				"transition": "transition.slideRightBigOut",
				"duration": 250
			}
		}
	}
};